import api from 'App/api'
import urls from 'App/utils/urls'
import { preUni, preEntranceExam, semi } from 'App/enums/grades'
import profilesTypes from 'App/enums/profilesTypes'

const isDevelopmentMode = process.env.REACT_APP_MODE !== 'production'

async function getUserData() {
  const { data } = await api.get(`${urls.AUTH_API}/v1/users/me`)
  const [profile] = data.profiles

  return {
    grades: data.grades.map(({ id }) => id),
    profileId: profile.id,
  }
}

function getIsValidAccessByGrade(gradeId) {
  const allowedGrades = new Set([
    preUni,
    preEntranceExam,
    semi,
  ])

  return Array.isArray(gradeId)
    ? gradeId.some((id) => allowedGrades.has(id))
    : allowedGrades.has(gradeId)
}

function getIsValidAccessProfile(profileId) {
  const notAllowedProfiles = new Set([
    profilesTypes.school,
    profilesTypes.coordinator,
    profilesTypes.teacher,
    profilesTypes.consultant,
    profilesTypes.financialManager,
    profilesTypes.orderStore,
    profilesTypes.schoolAdministration,
    profilesTypes.student,
  ])

  return !notAllowedProfiles.has(profileId)
}

async function getIsAllowedAccess() {
  const { grades, profileId } = await getUserData()

  const isValidAccessByGrade = getIsValidAccessByGrade(grades)
  const isValidAccessProfile = getIsValidAccessProfile(profileId)

  const isStudent = profileId === profilesTypes.student
  const isAllowedStudent = isStudent && isValidAccessByGrade

  return isDevelopmentMode ? true : isAllowedStudent || isValidAccessProfile
}

export default getIsAllowedAccess
