import { Redirect, Route, Switch } from 'react-router-dom'
import {
  Suspense,
  useEffect,
  useState,
} from 'react'
import PropTypes from 'prop-types'

import { ScreenClassProvider, setConfiguration } from 'react-grid-system'
import {
  isAuthenticated,
  redirectToLogin,
  getAuthSession,
  isTrustedDomain,
  isValidUrl,
  logout,
} from 'App/utils/auth'
import { toastContainer } from 'App/utils/toast'
import { MediaQueryProvider } from 'App/utils/mediaQuery'
import breakpoints from 'App/utils/breakpoints'

import WithoutMenuLayout from 'Layouts/withoutMenu'
import WithMenuLayout from 'Layouts/withMenu'

import { useSelector } from 'react-redux'
import routes from './routes'

import './index.scss'
import Loading from './components/Loading/Loading'
import TermsOfUse from './components/TermsOfUse/TermsOfUse'
import getIsAllowedAccess from './utils/restrictAccess'
import httpCodes from './enums/httpCodes'
import urls from './utils/urls'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.

const getRedirectURL = (
  currentPath,
  isRequiredAppendTokenValue = null,
  urlToRedirectValue = null,
) => {
  const allowedRedirectRoutesNames = [
    '/',
    '/login', // if future login with react
  ]
  const isValidPath = allowedRedirectRoutesNames.includes(currentPath)
  if (urlToRedirectValue && isValidPath) {
    try {
      if (!isValidUrl(urlToRedirectValue) || !isTrustedDomain(urlToRedirectValue)) {
        return null
      }
      const url = new URL(urlToRedirectValue)
      if (JSON.parse(isRequiredAppendTokenValue)) {
        const sessionToken = getAuthSession()
        url.searchParams.set('token', sessionToken)
      }

      return url.toString()
    } catch (error) {
      logout({ redirect: urlToRedirectValue })
    }
  }

  return null
}

const PrivateRoute = ({ children, ...rest }) => {
  const currentPath = rest.path
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const appendTokenValue = urlParams.get('appendToken')
  const redirectValue = urlParams.get('redirect')
  const currentRoute = routes.find((route) => route.path === rest.path)
  if (!isAuthenticated() && !currentRoute.meta?.allowAnonymous) {
    redirectToLogin()
  } else if (appendTokenValue || redirectValue) {
    const redirectRoute = getRedirectURL(currentPath, appendTokenValue, redirectValue)

    if (redirectRoute !== null) {
      window.location.assign(redirectRoute)
    }
  }

  return <Route {...rest} />
}

PrivateRoute.defaultProps = {
  children: null,
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
}

const RouteWithSubRoutes = ({ path, component: Component, routes: subRoutes }) => (
  <PrivateRoute
    path={path}
    render={(props) => (
      <Component
        {...props}
        routes={subRoutes}
      />
    )}
  />
)

RouteWithSubRoutes.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.string),
}

RouteWithSubRoutes.defaultProps = {
  routes: [],
}

const PageLayout = ({ meta, children }) => {
  const withoutMenuRoute = meta?.withoutMenu

  return withoutMenuRoute ? (
    <WithoutMenuLayout>
      {children}
    </WithoutMenuLayout>
  ) : (
    <WithMenuLayout>
      {children}
    </WithMenuLayout>
  )
}

PageLayout.propTypes = {
  meta: PropTypes.shape({
    allowAnonymous: PropTypes.bool,
    withoutMenu: PropTypes.bool,
  }),
  children: PropTypes.node.isRequired,
}

PageLayout.defaultProps = {
  meta: {
    allowAnonymous: false,
    withoutMenu: false,
  },
}

setConfiguration({
  breakpoints,
  gutterWidth: 24,
  maxScreenClass: 'xl',
})

const App = () => {
  const { loggedUser } = useSelector((state) => state.users)
  const [
    isLoading,
    setIsLoading,
  ] = useState(false)
  const [
    isValidAccess,
    setIsValidAccess,
  ] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    async function checkAccess() {
      const result = await getIsAllowedAccess()

      setIsValidAccess(result)
      setIsLoading(false)
    }

    checkAccess()
  }, [])

  useEffect(() => {
    const isNotValidAccess = isValidAccess === false

    if (isNotValidAccess) {
      window.location.assign(`${urls.PORTAL_URL}/error/${httpCodes.forbidden}`)
    }
  }, [isValidAccess])

  const canRenderApp = !isLoading && isValidAccess

  return canRenderApp ? (
    <Suspense fallback={<Loading />}>
      <MediaQueryProvider>
        <ScreenClassProvider>
          <Switch>
            {routes.map((route) => (
              <PageLayout
                key={route.name}
                {...route}
              >
                {loggedUser
                && loggedUser?.user?.acceptedTermsOfServiceVersion === null
                && <TermsOfUse />}

                <RouteWithSubRoutes
                  key={route.name}
                  {...route}
                />
              </PageLayout>
            ))}

            <Redirect
              from="*"
              to="/"
            />
          </Switch>

          {toastContainer}
        </ScreenClassProvider>
      </MediaQueryProvider>
    </Suspense>
  ) : null
}

export default App
